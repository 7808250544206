
import { format } from 'date-fns'
import { securityNotificationsQuery } from '@/queries/securityNotifications.gql'
import { vulnerabilityReportsQuery } from '@/queries/vulnerabilityReports.gql'
import { getDateDistance } from '@/utils'
import { latestNews as latestNewsQuery } from '@/queries/news/newsPosts.gql'

const NOTIFICATIONS_ITEMS_PER_PAGE = 4
const REPORTS_ITEMS_PER_PAGE = 6

const formatObjectToWTBFilterArray = (model = {}) => {
  return Object.entries(model).map(([key, value]) => ({
    title: value || '',
    code: `${key}`,
    isSelected: false,
  }))
}

const prepareVulnerabilityReportsFilterBlock = ({
  title,
  listObject,
  key,
  isSearchable = false,
  showableOptions = false,
}) => ({
  title,
  list: formatObjectToWTBFilterArray(listObject),
  isSearchable,
  showableOptions,
  key,
})

const REPORT_FILTER_BLOCKS = ['productCategories', 'products']

export default {
  name: 'VulnerabilityReport',
  async asyncData({ app, i18n, $config }) {
    let latestNews
    let initialNotifications
    let notificationsTotalPages
    let reports

    try {
      const { data } = await app.$gqlQuery({
        query: latestNewsQuery,
        fetchPolicy: 'reload',
        variables: {
          postsLimit: 3,
          domainId: $config.domainId,
          languageCode: i18n.locale,
          hashtag: 'security',
        },
      })

      if (!data?.latestNews?.data?.length) console.error('No latestNews')

      latestNews = data?.latestNews?.data || false
    } catch {
      latestNews = false
    }

    try {
      const {
        data: { securityNotifications },
      } = await app.$gqlQuery({
        query: securityNotificationsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          first: NOTIFICATIONS_ITEMS_PER_PAGE,
          domainId: $config.domainId,
          languageCode: i18n.locale,
        },
      })

      if (
        securityNotifications?.data &&
        Array.isArray(securityNotifications.data)
      ) {
        initialNotifications = securityNotifications.data.map(
          (notification) => ({
            ...notification,
            date: format(
              new Date(notification.notificationDate),
              'Y-MM-dd hh:mm',
            ),
          }),
        )
      }

      const notificationsTotalItems =
        securityNotifications?.paginatorInfo?.total ||
        initialNotifications?.length

      if (notificationsTotalItems) {
        notificationsTotalPages = Math.ceil(
          notificationsTotalItems / NOTIFICATIONS_ITEMS_PER_PAGE,
        )
      }
    } catch {
      initialNotifications = []
    }

    try {
      const {
        data: { vulnerabilityReports },
      } = await app.$gqlQuery({
        query: vulnerabilityReportsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          languageCode: i18n.locale,
        },
      })

      reports = vulnerabilityReports.data || []
    } catch {
      reports = []
    }

    return {
      vulnerabilityReports: reports,
      latestNews,
      notificationsTotalPages,
      initialNotifications,
    }
  },
  data() {
    return {
      activeTab: 0,
      notificationsActivePage: 1,
      showVulnerabilityForm: false,
      isNotificationsLoading: false,
      reportsActivePage: 1,
      isNotificationsExpanded: false,
      notificationSearchValue: '',
      activeNotifications: {},
      searchResultsCount: 0,
      isReportFiltersOpen: false,
      reportSearchValue: '',
      filters: null,
      pageData: {},
      selectedSortTag: {
        code: 'date',
        title: 'Date',
      },
    }
  },
  navigation: {
    disableTransparency: true,
  },
  computed: {
    bannerBackgroundImage() {
      return this.pageData?.files?.['vulnerability.banner.background.image']
    },
    tabs() {
      return ['Security notifications', 'Report a vulnerability', 'Reports']
    },
    notifications() {
      if (
        !this.notificationSearchValue ||
        this.notificationSearchValue.length < 2
      )
        return this.initialNotifications

      return this.initialNotifications.map((notification) => ({
        ...notification,
        titleTranslated: this.highlightSearchedText(
          notification.titleTranslated,
          notification.id,
        ),
        descriptionTranslated: this.highlightSearchedText(
          notification.descriptionTranslated,
          notification.id,
        ),
        date: this.highlightSearchedText(notification.date, notification.id),
      }))
    },
    filteredReports() {
      if (!this.filters) {
        return this.vulnerabilityReports
      }

      const selected = this.getSelectedFilters(this.filters)
      let list = this.vulnerabilityReports
      const filterByProductCategories = (reports) => {
        return reports.filter((report) =>
          selected?.productCategories?.some((selectedCategory) =>
            report.product_type_concatenated
              .split(', ')
              .includes(selectedCategory),
          ),
        )
      }
      const filterByProduct = (reports) =>
        reports.filter((report) =>
          selected?.products?.some((selectedProduct) =>
            report?.affected_products_mapped
              .reduce((prev, affectedProduct) => {
                return [...prev, ...affectedProduct.products.split(', ')]
              }, [])
              .includes(selectedProduct),
          ),
        )

      const filterBySearchValue = (reports) => {
        return reports.filter((report) =>
          report.title_translated
            .toLowerCase()
            .includes(this.reportSearchValue.toLowerCase()),
        )
      }

      if (selected?.productCategories?.length > 0) {
        list = filterByProductCategories(list)
      }

      if (selected?.products?.length > 0) {
        list = filterByProduct(list)
      }

      if (this.reportSearchValue) {
        list = filterBySearchValue(list)
      }

      return list
    },
    sortedReports() {
      const list = [...this.filteredReports]

      const severities = ['low', 'medium', 'high', 'critical']

      if (this.selectedSortTag.code === 'date') {
        return list.sort((reportA, reportB) => {
          return new Date(reportB.report_date) - new Date(reportA.report_date)
        })
      }

      if (this.selectedSortTag.code === 'last_update') {
        return list.sort((reportA, reportB) => {
          return (
            new Date(reportB.last_updated_at) -
            new Date(reportA.last_updated_at)
          )
        })
      }

      if (this.selectedSortTag.code === 'severity_high') {
        return list.sort((reportA, reportB) => {
          return (
            severities.indexOf(reportB.severity) -
            severities.indexOf(reportA.severity)
          )
        })
      }

      if (this.selectedSortTag.code === 'severity_low') {
        return list.sort((reportA, reportB) => {
          return (
            severities.indexOf(reportA.severity) -
            severities.indexOf(reportB.severity)
          )
        })
      }

      return list
    },
    activePageReports() {
      return this.sortedReports.slice(
        REPORTS_ITEMS_PER_PAGE * (this.reportsActivePage - 1),
        REPORTS_ITEMS_PER_PAGE * this.reportsActivePage,
      )
    },
    reportsTotalPages() {
      return Math.ceil(this.filteredReports.length / REPORTS_ITEMS_PER_PAGE)
    },
    sortTags() {
      return [
        {
          code: 'date',
          title: 'Date',
        },
        {
          code: 'last_update',
          title: 'Last update',
        },
        {
          code: 'severity_high',
          title: 'Severity High',
        },
        {
          code: 'severity_low',
          title: 'Severity Low',
        },
      ]
    },
  },
  watch: {
    filters: {
      handler(filters) {
        const selectedFilters = filters.reduce((previous, current) => {
          let previousClone = [...previous]
          current.list.forEach((listItem) => {
            if (listItem.isSelected) {
              previousClone = [
                ...previousClone,
                encodeURIComponent(listItem.code),
              ]
            }
          })

          return previousClone
        }, [])
        if (Array.isArray(selectedFilters))
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                report_filter: selectedFilters,
              },
            })
            .catch(() => {}) // vuejs hack https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue#answer-62465003
      },
      deep: true,
    },
    reportSearchValue(newSearch) {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            report_search: encodeURIComponent(newSearch),
          },
        })
        .catch(() => {})
    },
    notificationSearchValue() {
      this.searchResultsCount = 0
    },
  },
  created() {
    this.pageData = this.$store.state.page.content

    const pageDataFilter = this.$store.state.page.content?.filter

    if (pageDataFilter) {
      this.filters = this.preselectFromQuery(
        REPORT_FILTER_BLOCKS.map((block) =>
          prepareVulnerabilityReportsFilterBlock({
            title: pageDataFilter?.[block]?.[0]?.title,
            listObject: pageDataFilter?.[block]?.[0]?.list,
            isSearchable: pageDataFilter?.[block]?.[0]?.show_search,
            showableOptions: pageDataFilter?.[block]?.[0]?.showable_options,
            key: block,
          }),
        ),
      )
    }
  },
  methods: {
    async changeNotificationsPage(page) {
      this.isNotificationsLoading = true

      try {
        const {
          data: { securityNotifications },
        } = await this.$gqlQuery({
          query: securityNotificationsQuery,
          variables: {
            first: NOTIFICATIONS_ITEMS_PER_PAGE,
            page,
            domainId: this.$config.domainId,
            languageCode: this.$i18n.locale,
          },
        })

        if (
          securityNotifications?.data &&
          Array.isArray(securityNotifications.data)
        ) {
          this.initialNotifications = securityNotifications.data.map(
            (notification) => ({
              ...notification,
              date: format(
                new Date(notification.notificationDate),
                'Y-MM-dd hh:mm',
              ),
            }),
          )
        }

        this.notificationSearchValue = ''
        this.notificationsActivePage = page
      } finally {
        this.isNotificationsLoading = false
      }
    },
    preselectFromQuery(filters = []) {
      const filterClone = [...filters]
      try {
        const filtersParams = this.$route.query?.report_filter || []
        if (this.$route.query?.report_search) {
          this.reportSearchValue = this.$route.query.report_search
        }
        filterClone.forEach((filterItem) => {
          filterItem.list.forEach((listItem) => {
            if (filtersParams.includes(encodeURIComponent(listItem.code))) {
              listItem.isSelected = true
            }
          })
        })
        return filterClone
      } catch (ex) {
        console.log(ex)
        return filters
      }
    },
    filterReports() {
      //
    },
    sortReports() {
      //
    },
    searchReports(value) {
      this.reportSearchValue = value
    },
    changeReportsPage(page) {
      this.reportsActivePage = page
    },
    getFormattedDate(date) {
      return date
        ? getDateDistance(date, this.$store.state['date-fns'].activeLocale)
        : ''
    },
    getSelectedFilters(filters = []) {
      const selectedFilters = REPORT_FILTER_BLOCKS.reduce(
        (previous, current) => ({
          ...previous,
          [current]: [],
        }),
        {},
      )

      filters.forEach((filter) => {
        filter.list.forEach((item) => {
          if (!item.isSelected) {
            return
          }

          REPORT_FILTER_BLOCKS.forEach((key) => {
            if (key === filter.key) {
              selectedFilters[key].push(item.title)
            }
          })
        })
      })

      return selectedFilters
    },
    highlightSearchedText(text, id) {
      const node = new DOMParser().parseFromString(text, 'text/html').body
      this.replaceNodes(node, id)

      return node.innerHTML
    },
    replaceNodes(node, id) {
      const replaceWithNewNode = (text) => {
        const span = document.createElement('span')
        span.className = 'font-bold text-blue-800'
        span.innerHTML = text
        return span
      }
      if (node.nodeType === Node.TEXT_NODE) {
        const regex = new RegExp(this.notificationSearchValue, 'gi')
        const parts = node.textContent.split(regex)

        if (parts.length > 1) {
          const fragment = document.createDocumentFragment()
          let lastIndex = 0

          node.textContent.replace(regex, (match, index) => {
            fragment.appendChild(
              document.createTextNode(node.textContent.slice(lastIndex, index)),
            )
            fragment.appendChild(replaceWithNewNode(match))
            lastIndex = index + match.length

            this.searchResultsCount++
          })

          fragment.appendChild(
            document.createTextNode(node.textContent.slice(lastIndex)),
          )
          node.parentNode.replaceChild(fragment, node)

          this.activeNotifications[id] = true
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((item) =>
          this.replaceNodes(item, id),
        )
      }
    },
  },
}
